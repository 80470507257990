















import { Vue, Component } from 'vue-property-decorator';
import { domainConfig } from '@/config';
import { AppModule } from '@/store/modules/app';
import { loadingApply } from '@/api/home';
import RegistDialog from './RegistDialog.vue';
import utils from '@/utils/index';

@Component({
    components: {
        RegistDialog
    }
})
export default class Header extends Vue {
    registDlgVisible = false

    openRepairWeb() {
        (this as any).saveEventTracking('272');
        window.open(`${domainConfig.repair}#/home/otp?langCode=${AppModule.locale}&fromOfficialLogin=true`);
    }

    openRegistDlg() {
        loadingApply({
            landingPageType: 3,
            applySystemElementId: 263,
            country: '1001'
        });
        (this as any).saveEventTracking('271');
        (window as any).dataLayer.push({
            event: 'free_trial_open'
        });
        utils.fbq('trackCustom', 'free_trial_open');
        this.registDlgVisible = true;
    }

}
