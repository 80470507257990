






import {
    Vue, Component, Prop
} from 'vue-property-decorator';

@Component
export default class CopyButton extends Vue {
    $message: any;
    $t: any;

    @Prop({ default: '' }) text!: string

    copyText() {
        let copyInput: any;
        if (document.getElementById('copyInputComponent')) {
            copyInput = document.getElementById('copyInputComponent');
        } else {
            copyInput = document.createElement('input');
            copyInput.style.position = 'fixed';
            copyInput.style.zIndex = -1;
            copyInput.id = 'copyInputComponent';
            document.body.appendChild(copyInput);
        }
        copyInput.value = this.text;
        copyInput.select();
        document.execCommand('Copy');
        this.$message.success(this.$t('EGGRepair.copied') as string);
        this.$emit('copy');
    }
}
