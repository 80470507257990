











import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import RegistForm from './RegistForm.vue';

@Component({
    components: {
        RegistForm
    }
})
export default class RegistDialog extends Vue {
    @PropSync('visible', {
        type: Boolean,
        default: false
    })
    dialogVisible!: any;

}
