export default {
    openUrl(url:string) {
        if (!url) {
            return;
        }
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.click();
    },
    debounce(f:any, wait = 50) {
        let timer:any = null;
        return function() {
            if (timer) clearTimeout(timer);
            timer = setTimeout(f, wait);
        };
    },
    fbq(...args: any[]) {
        if (window.fbq) {
            window.fbq(...args);
        }
    }
};
