














































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getCountry, applyForUse, sendPhoneCode } from '@/api/EGGRepair';
import { ElForm } from 'element-ui/types/form';
import { AppModule } from '@/store/modules/app';
import CopyButton from './CopyButton.vue';
import utils from '@/utils/index';
import { domainConfig } from '@/config';

@Component({
    components: {
        CopyButton
    }
})
export default class RegistForm extends Vue {
    $i18n: any
    $t: any

    @Prop({
        default: false
    }) isDlg!: boolean // 是否是弹窗中的表单

    startForm:any = {
        shopName: '',
        staffCount: '',
        contactName: '',
        cellphone: '',
        prefix: '+66',
        phoneCode: '',
        email: null
    }

    loading = false

    successDlgVisible = false

    passwordStatus = ''

    applyCode = ''

    countryList:any = []

    disableSendCode = true

    codeInterval: any = null

    codeSeconds = 0

    sendLoading = false

    countryFlag = ''

    get sendCodeDisabled() {
        return !!(this.disableSendCode || this.codeSeconds);
    }

    get repairUrl() {
        return domainConfig.repair;
    }

    get startFormRules() {
        const phoneValidator = (rule: any, value: string, callback: any) => {
            const country = this.countryList.filter((item:any) => item.areaCode == this.startForm.prefix.slice(1));
            const mobileRule = new RegExp(country[0].mobileRule);
            let testValue = '';
            if (value[0] === '0') {
                testValue = value.slice(1);
            } else {
                testValue = value;
            }
            if (!value) {
                callback(this.$i18n.t('EGGRepair.reqiuredFieldTips'));
            } else if (!mobileRule.test(testValue)) {
                this.disableSendCode = true;
                callback(this.$i18n.t('EGGAlai.checkPhonenumber'));
            } else {
                this.disableSendCode = false;
                callback();
            }
        };
        const emailValidator = (rule:any, value:string, callback:any) => {
            if (!value) {
                callback();
                return;
            }
            const isValidEmail = (str:string) => /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(str);
            if (isValidEmail(value)) {
                callback();
            } else {
                callback(this.$i18n.t('EGGAlai.checkFormatTips'));
            }
        };
        const phoneCodeValidator = (rule:any, value:string, callback:any) => {
            if (/^[a-zA-Z0-9]{4,6}$/.test(value)) {
                callback();
            } else {
                callback(this.$i18n.t('EGGAlai.checkFormatTips'));
            }
        };
        const employeeValidator = (rule:any, value:any, callback:any) => {
            if (value === '0') {
                callback(this.$i18n.t('index.employeeNumberTips'));
            } else {
                callback();
            }
        };
        return {
            shopName: [
                { required: true, message: this.$i18n.t('EGGRepair.reqiuredFieldTips'), trigger: 'blur' }
            ],
            staffCount: [
                { required: true, message: this.$i18n.t('EGGRepair.reqiuredFieldTips'), trigger: 'blur' },
                { validator: employeeValidator, trigger: 'blur' }
            ],
            contactName: [
                { required: true, message: this.$i18n.t('EGGRepair.reqiuredFieldTips'), trigger: 'blur' }
            ],
            cellphone: [
                { validator: phoneValidator, trigger: ['change', 'blur'] }
            ],
            phoneCode: [
                { required: true, message: this.$i18n.t('EGGRepair.reqiuredFieldTips'), trigger: 'blur' },
                { validator: phoneCodeValidator, trigger: 'blur' }
            ],
            email: [
                { validator: emailValidator, trigger: 'blur' }
            ]
        };
    }

    created() {
        this.getCountries();
    }

    getCountries() {
        getCountry({ countryDisplayType: 'ALL' }).then((res:any) => {
            if (res.code === '200') {
                const data = res.data;
                this.countryList = data.items;
                const country = this.countryList.filter((item:any) => item.areaCode == this.startForm.prefix.slice(1));
                this.countryFlag = country[0].countryFlag;
            }
        });
    }

    changeAreaCode() {
        const country = this.countryList.filter((item:any) => item.areaCode == this.startForm.prefix.slice(1));
        this.countryFlag = country[0].countryFlag;
    }

    sendCode() {
        const params = {
            cellphone: this.startForm.cellphone,
            prefix: this.startForm.prefix
        };
        const prefix = params.prefix.slice(1);
        const countryId = this.countryList.find((item: any) => item.areaCode === prefix).countryId;
        this.sendLoading = true;
        sendPhoneCode(params, countryId).then((res: any) => {
            if (res.code === '20049024') {
                this.$message.success(this.$t('EGGRepair.sendVerificationCodeSuccess'));
                this.codeSeconds = 60;
                this.codeInterval = setInterval(() => {
                    if (this.codeSeconds > 0) {
                        this.codeSeconds--;
                    } else {
                        clearInterval(this.codeInterval);
                    }
                }, 1000);
            } else if (res.code === '1202') {
                this.$message.error(this.$t('EGGRepair.sendCodeErrTips'));
            }
        }).finally(() => {
            this.sendLoading = false;
        });
        (this as any).saveEventTracking(this.isDlg ? '273' : '275');
    }

    handleSubmit() {
        (this as any).saveEventTracking(this.isDlg ? '274' : '276');
        (this.$refs.startForm as ElForm).validate().then((valid: boolean) => {
            if (valid) {
                const confirmData = Object.assign({}, this.startForm);
                confirmData.prefix = confirmData.prefix.slice(1);
                confirmData.staffCount = parseInt(confirmData.staffCount);
                confirmData.clientSource = 'REPAIR_OFFICIAL_WEB';
                this.loading = true;
                applyForUse(confirmData).then((res:any) => {
                    this.applyCode = res.code;
                    this.successDlgVisible = true;
                    this.passwordStatus = '';
                    if (res.code === '200') {
                        this.loading = false;
                        this.passwordStatus = res.data.passwordStatus;
                        (window as any).dataLayer.push({
                            event: 'free_trial_submit'
                        });
                        utils.fbq('trackCustom', 'free_trial_submit');
                    }
                }).finally(() => {
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        });
    }

    openRepairWeb(elementId?: string) {
        if (elementId) {
            (this as any).saveEventTracking(elementId);
        }
        window.open(`${this.repairUrl}#/home/login?prefix=${encodeURIComponent(this.startForm.prefix)}&cellphone=${this.startForm.cellphone}&langCode=${AppModule.locale}&fromOfficialLogin=true`);
    }
}
