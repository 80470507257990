import request from '../utils/request';

// 数据埋点
export const loadingApply = (data:any) => {
    return request({
        url: '/coaster/web-api/v1/eventTracking/loading/apply',
        method: 'POST',
        data
    });
};

// 提交表单
export const submitFreetrial = (data: any) => {
    return request({
        url: '/platform/loading/page/submit',
        method: 'POST',
        data
    });
};



// 发送验证码
export const sendCode = (data: any, authUserSystem:string) => {
    return request({
        url: '/platform/loading/page/send',
        method: 'POST',
        data,
        headers: {
            authUserSystem
        }
    });
};

// 获取前缀

export const getCodeList = (system:string) => {
    return request({
        url: '/platform/loading/page/list',
        method: 'GET',
        headers: {
            authUserSystem: system
        }
    });
};

