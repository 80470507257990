import Vue from 'vue';

export default {
    /**
     * seo优化，设置一些meta标签
     * @param data meta中的数据
     */
    seoOptimize(data: any) {
        if (process.env.NODE_ENV === 'production') {
            document.title = data.title;
            (<any>document.getElementById('metaKeywords')).content = data.keywords;
            (<any>document.getElementById('metaDescription')).content = data.description;
            (<any>document.getElementById('metaOgUrl')).content = location.href;
            (<any>document.getElementById('metaOgTitle')).content = data.title;
            (<any>document.getElementById('metaOgDescription')).content = data.description;
            (<any>document.getElementById('metaTwitterTitle')).content = data.title;
            (<any>document.getElementById('metaTwitterDescription')).content = data.description;
        }
    }
};
