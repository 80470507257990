import { render, staticRenderFns } from "./RegistDialog.vue?vue&type=template&id=417a8e85&scoped=true&"
import script from "./RegistDialog.vue?vue&type=script&lang=ts&"
export * from "./RegistDialog.vue?vue&type=script&lang=ts&"
import style0 from "./RegistDialog.vue?vue&type=style&index=0&id=417a8e85&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "417a8e85",
  null
  
)

export default component.exports